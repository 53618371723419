import gsap from 'gsap';
import anime from 'animejs/lib/anime.es.js';

class Global{
	constructor() {
		this.data = {
      body: document.querySelector('body'),
		};
		this.init();
	}
  
	init() {    
    console.log('GLOBALS');
		let that = this;
    let FollowBox = document.querySelector(".fairy");
    let grad = document.querySelector(".grad");
    
    let relXFairy = null;
    let relYFairy = null;
    let relXGrad = null;
    let relYGrad = null;
    
    // window.addEventListener('scroll', () => {
    //   if(window.pageYOffset > 1){
    //     gsap.to(".main-header", {backgroundColor: 'rgba(0, 0, 0, 0.3)', duration: .3})
    //   }
    //   else{
    //     gsap.to(".main-header", {backgroundColor: 'transparent', duration: .3})
    //   }
    // })
    
    window.addEventListener('mousemove', e => {      
      if(!document.body.classList.contains('is-mobile')){
        relXFairy = e.x - FollowBox.offsetLeft - (FollowBox.getBoundingClientRect().width / 2);
        relYFairy = e.y - FollowBox.offsetTop - (FollowBox.getBoundingClientRect().height / 2);
        gsap.to(".fairy-cursor", { x: relXFairy, y: relYFairy, duration: .2, stagger: 0.004, overwrite: "auto"});

        relXGrad = e.x - grad.offsetLeft - (grad.getBoundingClientRect().width / 2);
        relYGrad = e.y - grad.offsetTop - (grad.getBoundingClientRect().height / 2);
        gsap.to(".grad", { x: relXGrad, y: relYGrad, duration: .2});
      }      
    });

    document.querySelector('body').addEventListener('mousedown', () => {
      gsap.to(".fairy-cursor", {scale: 1.2, duration: .5, ease: "back.out(1.7)"});      
    });
    document.querySelector('body').addEventListener('mouseup', e => {
      gsap.to(".fairy-cursor", {scale:1, duration: .5, ease: "back.out(1.7)"});
    });

    //Sparks
    let container = document.querySelector(".dust");
    let sparks = [];
    let sparksIndex = 0;
    let a = 10;
    // I did a test and it seems mousemove only fires every frame (screen refresh), so sparkCount should be more than the frames a sec they get (so 60 for me).
    let sparkCount = 60;
    let sparkParticleCount = 5;
    function lerp(p1, p2, t) {
      return p1 + (p2 - p1) * t;
    }
    //https://gist.github.com/gre/1650294
    const ease = {
      // no easing, no acceleration
      linear: (t) => t,
      // accelerating from zero velocity
      inQuad: (t) => t * t,
      // decelerating to zero velocity
      outQuad: (t) => t * (2 - t),
      // acceleration until halfway, then deceleration
      inOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
      // accelerating from zero velocity
      inCubic: (t) => t * t * t,
      // decelerating to zero velocity
      outCubic: (t) => --t * t * t + 1,
      // acceleration until halfway, then deceleration
      inOutCubic: (t) =>
        t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
      // accelerating from zero velocity
      inQuart: (t) => t * t * t * t,
      // decelerating to zero velocity
      outQuart: (t) => 1 - --t * t * t * t,
      // acceleration until halfway, then deceleration
      inOutQuart: (t) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
      // accelerating from zero velocity
      inQuint: (t) => t * t * t * t * t,
      // decelerating to zero velocity
      outQuint: (t) => 1 + --t * t * t * t * t,
      // acceleration until halfway, then deceleration
      inOutQuint: (t) =>
        t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
    };

    // https://stackoverflow.com/questions/5837572/generate-a-random-point-within-a-circle-uniformly
    function circularRandom(r) {
      // Math.abs(Math.random() - Math.random()) makes it have a bias towards 0, or in our case the center
      r = r * Math.sqrt(Math.abs(Math.random() - Math.random()));
      var theta = Math.random() * 2 * Math.PI;
      var x = r * Math.cos(theta);
      var y = r * Math.sin(theta);
      return { x: x, y: y, r: r };
    }

    // Create the sparks
    for (var i = 0; i <= sparkCount; i += 1) {
      var spark = { els: [] };

      for (var j = 0; j < sparkParticleCount; j++) {
        var dot = document.createElement("div");
        dot.classList.add("dot");
        container.appendChild(dot);
        spark.els.push(dot);

        var particleSize = anime.random(5, 20);
        var sparkRadius = 20;
        var { x, y ,r } = circularRandom(sparkRadius);

        // Make particles further from the center, smaller
        dot.style.width =
          lerp(particleSize, 1, ease.outQuad(r / sparkRadius)) + "px";
        dot.style.height =
          lerp(particleSize, 1, ease.outQuad(r / sparkRadius)) + "px";
        dot.style.opacity = "0";
        // by setting the particles start offset position using translate we can reposition the spark using left and top
        dot.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
      }

      spark.anime = new anime({
        targets: spark.els,
        loop: false,
        easing: "linear",
        autoplay: false,
        delay: anime.stagger(8),
        opacity: [
          { value: 0, duration: 0 },
          { value: 1, duration: 40 },
          {
            value: 0,
            duration: function () {
              return anime.random(500, 800);
            }
          }
        ],
        width: { value: 2, duration: 500 },
        height: { value: 2, duration: 500 },
        translateX: {
          value: function () {
            return anime.random(-30, 30);
          },
          duration: 800
        },
        translateY: {
          value: function () {
            return anime.random(-30, 30);
          },
          duration: 800
        }
      });
      sparks.push(spark);
    }

    // Mouse trail bit
    window.addEventListener(
      "mousemove",
      e =>  {
        anime.set(sparks[sparksIndex].els,
          {
          left: e.x,
          top: e.y,          
        });
        sparks[sparksIndex].anime.restart();
        sparksIndex++;
        if (sparksIndex == sparks.length) sparksIndex = 0;
      },
      false
    );


    //remove cursor in form
    document.querySelectorAll('form .form-inputs-wrapper').forEach(element => {
      element.addEventListener('mouseenter', () => {
        gsap.set(document.querySelectorAll('.fairy-cursor'), {opacity: 0});
        gsap.set(document.body, {cursor: 'auto'});
      })
    });
    document.querySelectorAll('form .form-inputs-wrapper').forEach(element => {
      element.addEventListener('mouseleave', () => {
        gsap.set(document.querySelectorAll('.fairy-cursor'), {opacity: 1});
        gsap.set(document.body, {cursor: 'none'});
      })
    });

    //CHANGE ACTIVE CLASS MENU
    let index;
    document.querySelectorAll('nav').forEach( nav => {
      nav.querySelectorAll('.menu li').forEach( element => {
        let parent = element.parentNode;
        element.querySelector('a').addEventListener('click', e => {
          index = Number(Array.prototype.indexOf.call(parent.children, element)) + 1;
          console.log(index);
          setTimeout(() => {
            nav.querySelector('.menu li.current-menu-item').classList.remove('current-menu-item');            
              nav.querySelector('.menu li:nth-child('+index+')').classList.add('current-menu-item');
          }, 300);
        })
      })      
    })
    

    //HIDE CONTENT WHEN BURGUER IS SHOWING
    document.querySelector('.burguer').addEventListener('click', event => {
      if(event.target.checked != undefined){
        if(event.target.checked){
          gsap.to('main>section>*', {opacity: 0})
        }
        else{
          gsap.to('main>section>*', {opacity: 1})
        }
      }      
    })

	}
}


export default Global;