import axios from "axios";

class RegistrationForm{
	constructor() {
		this.data = {            
			form: document.querySelector('.event-form'),
		};
 
		// this.handlerSubmit = this.handlerSubmit.bind(this);

		this.init();
	}
  
	init() {
    
    document.querySelectorAll('.event-form').forEach(element => {
      element.onsubmit = (e) => {
        e.preventDefault();
        const form = element;
        const formData = new FormData(form);
        
        axios({
          method: "post",
          url: window.location.protocol + "//" + window.location.hostname + "/wp-admin/admin-ajax.php?action=sendEventFromRegistration",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response.data)
                  
          element.querySelector("button").style.opacity = ".5";
          element.querySelector("button").style.pointerEvents = "none";
          if(response.data == 'exists'){                      
            element.querySelector('.response').style.opacity = '1';
            element.querySelector('.response').innerHTML = 'Email already registered!';
          }
          else if(response.data != 'error'){
            element.querySelector('.response').style.opacity = '1';
            element.querySelector('.response').innerHTML = 'You are registered to the event! See you there!';           
          }
          else{
            element.querySelector('.response').innerHTML = 'Error, please try again later!';
            element.querySelector('.response').style.opacity = '1';            
          }			
          
          setTimeout(() => {
            element.querySelector("button").style.opacity = "1";
            element.querySelector("button").style.pointerEvents = "all";
            element.querySelector('.response').style.opacity = '0';
            element.querySelector('.response').innerHTML = '';
          }, 2000);
        })
        .catch((response) => {
          //handle error
          console.log(response);
          // document.querySelector('.response').innerHTML = 'Error';
          // document.querySelector('.response').style.opacity = '1';
          // setTimeout(() => {
          //   document.querySelector('.response').style.opacity = '0';
          // }, 5000);
        })
      }
    });		
	}

}


export default RegistrationForm;