import Highway from '@dogstudio/highway';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from "gsap/ScrollTrigger";

import Globals from '../components/global'; 
import SignupForms from '../components//signup-forms/global'; 


class DefaultView extends Highway.Renderer{
  onEnter() {    
    const globals = new Globals();
    const signupforms = new SignupForms();
  }
  onLeave() {
    document.querySelectorAll('.dot').forEach( element => {
      element.remove();
    })
  }  
}
 
export default DefaultView;
 