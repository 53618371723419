import Highway from '@dogstudio/highway';
import gsap from 'gsap';

// Fade
class Fade extends Highway.Transition {
  in({ from, to, done }) {          
    document.querySelector('body').className = to.getAttribute('class');    
    // Reset Scroll
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });        
    console.log(to)
    //CHANGE LOGO
    document.querySelector('.main-header .logo a img').setAttribute('src', to.getAttribute('data-logo'));
    
    

    // Remove Old View and section body_class
    from.remove();
    document.querySelector('main > section').className = '';
    // document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);

    if(document.body.classList.contains('home')){
      document.querySelectorAll('nav').forEach(nav => {
        nav.querySelectorAll('.menu li').forEach( element => {          
          if(element.classList.contains('current-menu-item')){              
            element.classList.remove('current-menu-item');
          }          
          nav.querySelector('.menu li:first-of-type').classList.add('current-menu-item');           
        })                     
      })
    }
    //RESET MOBILE MENU (HIDE
    document.querySelector('.burguer input').checked = false;

    gsap.fromTo(to,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.5, 
        onComplete: done
      }
    ); 
    if(document.body.classList.contains('is-mobile')){
      gsap.to('.fairy-cursor, .grad', {
        x:'-39vw',
        y:'-43vh',      
        ease: "power2.in"
      });        
    }    
  }  
  
  out({ from, done }) {
    // Animation
    gsap.fromTo(from,
      { opacity: 1 },
      {
        opacity: 0,
        duration: 0.5,
        onComplete: done
      }
    );    
  }
  
}

export default Fade;

