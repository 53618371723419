import gsap from 'gsap';

class Events{
	constructor() {		;
		this.init();
	}
  
	init() {    
    console.log('events')
    
    document.querySelectorAll('.event h3').forEach( el => {
      el.addEventListener('click', target => {
        if(target.target.parentElement.classList.contains('active')){
          target.target.parentElement.classList.remove('active');
          gsap.to(target.target.nextElementSibling, {height: '0', duration: .3, ease: 'easeInOut'})
          gsap.to(target.target.querySelector('span'), {rotate: 0})
        }
        else{
          if(document.querySelector('.event.active')){
            gsap.to(document.querySelector('.event.active .event-content'), {height: '0', duration: .3, ease: 'easeInOut'})            
            gsap.to(document.querySelector('.event.active h3 span'), {rotate: 0})
            document.querySelector('.event.active').classList.remove('active');
          }
          target.target.parentElement.classList.add('active');
          gsap.to(target.target.nextElementSibling, {height: 'auto', duration: .3, ease: 'easeInOut'})
          gsap.to(target.target.querySelector('span'), {rotate: 180})
        }
                
      })
    })
  }
}


export default Events;