import Highway from '@dogstudio/highway';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from "gsap/ScrollTrigger";

import Globals from '../components/global'; 
import Events from '../components/events/global';
import RegistrationForm from '../components/events-registration/form';


class EventsView extends Highway.Renderer{
  onEnter() {    
    const globals = new Globals();
    const events = new Events();
    const registrationForm = new RegistrationForm();
  }
  onLeave() {
    document.querySelectorAll('.dot').forEach( element => {
      element.remove();
    })
  }  
}
 
export default EventsView;
 